window.showNotification = function(message, type, iconClass) {
    var notification = $(
        '<div class="notification ' + type + '">' +
        '<i class="fa-solid ' + iconClass + '"></i>' +
        '<span>' + message + '</span>' +
        '</div>'
    );

    var existingNotifications = $('.notification').length;

    var topPosition = 40 + (existingNotifications * 50);

    notification.css({
        top: topPosition + 'px'
    });

    $('body').append(notification);

    notification.fadeIn(300).css({
        transform: 'translateY(-20px)',
        opacity: 1
    });

    setTimeout(function() {
        notification.fadeOut(300, function() {
            $(this).remove();

            $('.notification').each(function(index) {
                $(this).css('top', 40 + (index * 50) + 'px');
            });
        });
    }, 3000);
}
