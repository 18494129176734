$(document).ready(function() {
    var initMainMenu = function() {
        const $menuItems = $('#menuItems .menu-sidebar-body-item');
        const $categoryItemsContainer = $('#categoryItems');
        const $productItemsContainer = $('#productItems');
        const $menuTitle = $('#menuTitle');
        const $backButton = $('#backButton');
        const $menuContainer = $('#menuItems');

        let currentMenuIndex = null;
        let currentCategoryIndex = null;

        function resetSidebar() {
            $menuContainer.removeClass('d-none');
            $categoryItemsContainer.addClass('d-none');
            $productItemsContainer.addClass('d-none');
            $backButton.addClass('d-none');
            $menuTitle.text('Menu');
        }

        $('#menuSidebar').on('hidden.bs.offcanvas', function() {
            resetSidebar();
        });

        function applySlideAnimation($element, direction, entering) {
            const enterClass = direction === 'left' ? 'slide-left-enter' : 'slide-right-enter';
            const exitClass = direction === 'left' ? 'slide-left-exit' : 'slide-right-exit';

            if (entering) {
                $element.addClass(enterClass);
                setTimeout(function() {
                    $element.addClass(`${enterClass}-active`);
                }, 0);
                setTimeout(function() {
                    $element.removeClass(`${enterClass} ${enterClass}-active`);
                }, 400);
            } else {
                $element.addClass(exitClass);
                setTimeout(function() {
                    $element.addClass(`${exitClass}-active`);
                }, 0);
                setTimeout(function() {
                    $element.removeClass(`${exitClass} ${exitClass}-active`);
                }, 400);
            }
        }

        function showCategories(menuIndex) {
            const selectedMenu = mainMenuItems[menuIndex];

            $categoryItemsContainer.empty();
            selectedMenu.children.forEach(function(group) {
                group.children.forEach(function(category) {
                    const categoryLabel = category.label;

                    const categoryItem = $('<div>', {
                        class: 'menu-sidebar-body-item py-2',
                        'data-category-index': category.categoryIndex, // Using categoryIndex
                        html: `<h5>${categoryLabel}</h5><i class="fa-solid fa-chevron-right"></i>`
                    });
                    $categoryItemsContainer.append(categoryItem);
                });
            });

            // Apply slide animation for categories
            applySlideAnimation($menuContainer, 'left', false);
            applySlideAnimation($categoryItemsContainer, 'left', true);

            // Update title and show categories
            $menuTitle.text(selectedMenu.label);
            $categoryItemsContainer.removeClass('d-none');
            $menuContainer.addClass('d-none');
            $productItemsContainer.addClass('d-none');
            $backButton.removeClass('d-none');
        }

        function showProductsDirectly(menuIndex) {
            const selectedMenu = mainMenuItems[menuIndex];
            const allProducts = selectedMenu.children.flatMap(group => group.children.flatMap(category => category.children));

            $productItemsContainer.empty();
            allProducts.forEach(function(product) {
                const productItem = $('<a>', {
                    class: 'menu-sidebar-body-item py-2 text-decoration-none',
                    href: product.url,
                    html: `<h5>${product.label}</h5>`
                });
                $productItemsContainer.append(productItem);
            });

            applySlideAnimation($menuContainer, 'left', false);
            applySlideAnimation($productItemsContainer, 'left', true);

            $menuTitle.text(selectedMenu.label);
            $productItemsContainer.removeClass('d-none');
            $menuContainer.addClass('d-none');
            $categoryItemsContainer.addClass('d-none');
            $backButton.removeClass('d-none');
        }

        function showProducts(categoryIndex) {
            const selectedCategory = mainMenuItems[currentMenuIndex].children.flatMap(group => group.children).find(category => category.categoryIndex == categoryIndex);

            $productItemsContainer.empty();
            selectedCategory.children.forEach(function(product) {
                const productItem = $('<a>', {
                    class: 'menu-sidebar-body-item py-2 text-decoration-none',
                    href: product.url,
                    html: `<h5>${product.label}</h5>`
                });
                $productItemsContainer.append(productItem);
            });

            applySlideAnimation($categoryItemsContainer, 'left', false);
            applySlideAnimation($productItemsContainer, 'left', true);

            $menuTitle.text(selectedCategory.label);
            $productItemsContainer.removeClass('d-none');
            $categoryItemsContainer.addClass('d-none');
            $backButton.removeClass('d-none');
        }

        $menuItems.on('click', function() {
            currentMenuIndex = $(this).attr('data-menu-index');
            const selectedMenu = mainMenuItems[currentMenuIndex];

            const hasCategoriesWithLabels = selectedMenu.children.some(group => group.children.some(category => category.label));

            if (!hasCategoriesWithLabels) {
                showProductsDirectly(currentMenuIndex);
            } else {
                showCategories(currentMenuIndex);
            }
        });

        $categoryItemsContainer.on('click', '.menu-sidebar-body-item', function() {
            currentCategoryIndex = $(this).attr('data-category-index');
            console.log(currentCategoryIndex);
            showProducts(currentCategoryIndex);
        });

        // Back button functionality
        $backButton.on('click', function() {
            if (currentMenuIndex === 5 && !$productItemsContainer.hasClass('d-none')) {
                applySlideAnimation($productItemsContainer, 'right', false);
                applySlideAnimation($menuContainer, 'right', true);
                $productItemsContainer.addClass('d-none');
                $menuContainer.removeClass('d-none');
                $backButton.addClass('d-none');
                $menuTitle.text('Menu');
            } else if (!$productItemsContainer.hasClass('d-none')) {
                applySlideAnimation($productItemsContainer, 'right', false);
                applySlideAnimation($categoryItemsContainer, 'right', true);
                $productItemsContainer.addClass('d-none');
                $categoryItemsContainer.removeClass('d-none');
                const selectedMenu = mainMenuItems[currentMenuIndex];
                $menuTitle.text(selectedMenu.label);
            } else {
                applySlideAnimation($categoryItemsContainer, 'right', false);
                applySlideAnimation($menuContainer, 'right', true);
                $categoryItemsContainer.addClass('d-none');
                $menuContainer.removeClass('d-none');
                $backButton.addClass('d-none');
                $menuTitle.text('Menu');
            }
        });

        function isTabletView() {
            return $(window).width() >= 768 && $(window).width() <= 1200;
        }

        $('.header-menu-item, .header-menu-subMenu').hover(function() {
            if (!isTabletView()) {
                $(this).closest('li').find('.header-menu-subMenu').addClass('d-block').removeClass('d-none');
                $(this).closest('li').find('.header-menu-item').addClass('active');
            }
        }, function() {
            if (!isTabletView()) {
                $(this).closest('li').find('.header-menu-subMenu').addClass('d-none').removeClass('d-block');
                $(this).closest('li').find('.header-menu-item').removeClass('active');
            }
        });

        $('.header-menu-item').on('click', function(e) {
            e.preventDefault();

            if (isTabletView()) {
                const menuIndex = $(this).data('menu-index');

                var offcanvasElement = document.getElementById('menuSidebar');
                var offcanvas = new bootstrap.Offcanvas(offcanvasElement);
                offcanvas.show()

                currentMenuIndex = menuIndex;

                const selectedMenu = mainMenuItems[menuIndex];
                const hasCategoriesWithLabels = selectedMenu.children.some(group => group.children.some(category => category.label));

                if (!hasCategoriesWithLabels) {
                    showProductsDirectly(menuIndex);
                } else {
                    showCategories(menuIndex);
                }
            } else {
                const submenu = $(this).siblings('.header-menu-subMenu');
                if (submenu.hasClass('d-block')) {
                    submenu.addClass('d-none').removeClass('d-block');
                    $(this).removeClass('active');
                } else {
                    $('.header-menu-subMenu').addClass('d-none').removeClass('d-block');
                    $('.header-menu-item').removeClass('active');
                    submenu.addClass('d-block').removeClass('d-none');
                    $(this).addClass('active');
                }
            }
        });

        $(document).on('click', function(e) {
            if (!$(e.target).closest('.header-menu').length) {
                $('.header-menu-subMenu').addClass('d-none').removeClass('d-block');
                $('.header-menu-item').removeClass('active');
            }
        });
    }

    if (typeof mainMenuItems === 'object') {
        initMainMenu();
    }
});
