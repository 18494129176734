import Swiper from 'swiper';
import { Autoplay, Mousewheel, Navigation, Pagination, Scrollbar } from 'swiper/modules';

const productGalleryMobileSlider = {
    init: function() {
        new Swiper('.product-showcase-mobile .swiper-mobile', {
            modules: [Autoplay, Mousewheel, Navigation, Pagination, Scrollbar],
            slidesPerView: 1,
            pagination: {
                el: '.swiper-pagination',
                type: 'fraction',
            },
            navigation: {
                nextEl: '.swiper-mobile-next',
                prevEl: '.swiper-mobile-prev',
            },
            autoplay: {
                delay: 5000,
            },
        });
    }
}

$(document).ready(function() {
    // Function to update the color label
    function updateColorLabel() {
        const selectedColor = document.querySelector('input[name="color"]:checked');
        const colorLabel = document.querySelector('#color_label');
        const notificationColorLabel = document.querySelector('#product-notification-color');
        if (selectedColor && colorLabel) {
            colorLabel.textContent = selectedColor.getAttribute('data-color');
            notificationColorLabel.textContent = selectedColor.getAttribute('data-color');
        }
        checkFormValidity();
    }

    // Function to update the size label
    function updateSizeLabel() {
        const selectedSize = document.querySelector('input[name="size"]:checked');
        const sizeLabel = document.querySelector('#size_label');
        const notificationSizeLabel = document.querySelector('#product-notification-size');
        if (selectedSize && sizeLabel) {
            sizeLabel.textContent = selectedSize.getAttribute('data-size');
            notificationSizeLabel.textContent = selectedSize.getAttribute('data-size');
        }
        checkFormValidity();
    }

    // Function to check if both size and color are selected
    function checkFormValidity() {
        const selectedColor = document.querySelector('input[name="color"]:checked');
        const selectedSize = document.querySelector('input[name="size"]:checked');
        const submitButton = document.querySelector('.product-content-submit');

        if (!submitButton) {
            return;
        }

        // Check if color and size inputs exist and at least one is required
        const colorInputsExist = document.querySelector('input[name="color"]') !== null;
        const sizeInputsExist = document.querySelector('input[name="size"]') !== null;

        if ((!colorInputsExist || selectedColor) && (!sizeInputsExist || selectedSize)) {
            submitButton.disabled = false;
        } else {
            submitButton.disabled = true;
        }
    }

    // Add event listeners to color and size inputs, but only if they exist
    const colorInputs = document.querySelectorAll('input[name="color"]');
    const sizeInputs = document.querySelectorAll('input[name="size"]');

    if (colorInputs.length > 0) {
        colorInputs.forEach(function (input) {
            input.addEventListener('change', updateColorLabel);
        });
        updateColorLabel(); // Initialize the color label if colors are present
    }

    if (sizeInputs.length > 0) {
        sizeInputs.forEach(function (input) {
            input.addEventListener('change', updateSizeLabel);
        });
        updateSizeLabel(); // Initialize the size label if sizes are present
    }

    const notificationDismiss = document.querySelector('#product-notification-dismiss');
    const notificationContainer = document.querySelector('#product-notification-container');
    const emailInput = document.querySelector('input[name="notification_email"]');
    const notificationButton = document.querySelector('.product-notification-form-btn');
    const notifyLink = document.querySelector('.product-content-unavailable a'); // The 'Get notified' link

    // Function to validate email format
    function isValidEmail(email) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    }

    // Event listener for dismissing the notification container
    notificationDismiss?.addEventListener('click', function (e) {
        e.preventDefault(); // Prevent default anchor behavior
        notificationContainer.classList.add('d-none');
    });

    // Event listener for showing the notification container when "Get notified" is clicked
    notifyLink?.addEventListener('click', function (e) {
        e.preventDefault(); // Prevent default anchor behavior
        notificationContainer.classList.remove('d-none'); // Show the notification container
    });

    // Event listener for the email button
    notificationButton?.addEventListener('click', function () {
        const emailValue = emailInput.value.trim();

        if (!emailValue || !isValidEmail(emailValue)) {
            showNotification('Incorrect email!', 'danger', 'fa-circle-xmark');
        } else {
            notificationContainer.style.display = 'none'; // Hide the notification container
            showNotification('Reminder set successfully!', 'success', 'fa-check-circle');
        }
    });

    const sizingGuideLink = document.querySelector('.product-content-sizes-guide a');

    if (sizingGuideLink) {
        sizingGuideLink?.addEventListener('click', function (event) {
            event.preventDefault(); // Prevent default link behavior

            // Extract the data-page value from the anchor element
            const pageUrl = sizingGuideLink.getAttribute('data-page');

            // Set the iframe src to the extracted page URL
            const sizingGuideIframe = document.getElementById('sizingGuideIframe');
            sizingGuideIframe.src = pageUrl;

            // Show the modal
            const sizingGuideModal = new bootstrap.Modal(document.getElementById('sizingGuideModal'));
            sizingGuideModal.show();
        });
    }

    checkFormValidity();

    if (!$('.product-showcase-mobile .swiper-mobile')) {
        return
    }

    productGalleryMobileSlider.init()
})