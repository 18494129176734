$(document).ready(function () {
    function updateTotals() {
        let cartTotal = 0;

        // Loop through each cart-product and update the product total
        $('.cart-product').each(function () {
            let price = parseFloat($(this).find('.cart-product-total').attr('data-price'));
            let quantity = parseInt($(this).find('.cart-product-quantity-input').val());

            if (!isNaN(price) && !isNaN(quantity)) { // Ensure both price and quantity are valid numbers
                let productTotal = price * quantity;

                // Update the individual product total (quantity * price)
                $(this).find('.cart-product-total').text('£' + productTotal.toFixed(2));
                $(this).find('.cart-product-total-mobile').text('£' + productTotal.toFixed(2));
                // Add to the cart-product total
                cartTotal += productTotal;
            }
        });

        // Update the grand total
        $('#cartTotal').text('£' + cartTotal.toFixed(2));
    }

    function checkStock(input) {
        let stockRemained = parseInt(input.attr('max'));
        let currentValue = parseInt(input.val());

        if (currentValue >= stockRemained) {
            input.val(stockRemained);
            input.closest('.cart-product-quantity').siblings('.no-stock').removeClass('d-none').addClass('d-flex');
            input.closest('.cart-product-quantity').siblings('.in-stock').removeClass('d-flex').addClass('d-none');
        } else {
            input.closest('.cart-product-quantity').siblings('.no-stock').removeClass('d-flex').addClass('d-none');
            input.closest('.cart-product-quantity').siblings('.in-stock').removeClass('d-none').addClass('d-flex');
        }

        updateTotals(); // Update totals when stock is checked
    }

    $('.cart-product-quantity-input').each(function () {
        checkStock($(this));
    });

    // Handle increment button
    $('.cart-product-quantity-increment').on('click', function () {
        let input = $(this).siblings('.cart-product-quantity-input');
        let maxStock = parseInt(input.attr('max'));
        let currentValue = parseInt(input.val());

        // If the current value is less than maxStock, increment the value
        if (currentValue < maxStock) {
            input.val(currentValue + 1);
        }
        checkStock(input);
    });

    // Handle decrement button
    $('.cart-product-quantity-decrement').on('click', function () {
        let input = $(this).siblings('.cart-product-quantity-input');
        let currentValue = parseInt(input.val());

        // Only decrement if currentValue is greater than 1
        if (currentValue > 1) {
            input.val(currentValue - 1);
        }
        checkStock(input);
    });

    // Handle manual input
    $('.cart-product-quantity-input').on('input', function () {
        let input = $(this);
        let value = parseInt(input.val());
        let maxStock = parseInt(input.attr('max'));

        // Ensure the value is within the allowed range
        if (value > maxStock) {
            input.val(maxStock);
        } else if (value < 1) {
            input.val(1);
        }

        checkStock(input);
    });

    // Initial total calculation on page load
    updateTotals();
});