const filterSidebar = {
    init: function () {
        const filterForm = $('#filterForm');
        const checkboxes = $('.filter-form-check-input');

        if (!filterForm.length || !checkboxes.length) {
            return;
        }

        const urlParams = new URLSearchParams(window.location.search);

        checkboxes.each(function() {
            const checkbox = $(this);
            if (urlParams.has(checkbox.attr('id'))) {
                checkbox.prop('checked', true);
            }
        });

        filterForm.on('submit', function(event) {
            event.preventDefault();

            const selectedFilters = {};

            checkboxes.each(function() {
                const checkbox = $(this);
                if (checkbox.prop('checked')) {
                    selectedFilters[checkbox.attr('id')] = 'on';
                }
            });

            checkboxes.each(function() {
                const checkbox = $(this);
                if (urlParams.has(checkbox.attr('id'))) {
                    urlParams.delete(checkbox.attr('id'));
                }
            });

            $.each(selectedFilters, function(key, value) {
                urlParams.set(key, value);
            });

            const newUrl = window.location.pathname + '?' + urlParams.toString();
            window.history.replaceState(null, '', newUrl);

            window.location.reload();
        });
    }
}

$(document).ready(function() {
    filterSidebar.init();
});
