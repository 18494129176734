function debounce(func, wait) {
    let timeout;
    return function() {
        const context = this;
        const args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}

function initializeExpander() {
    $('[data-expander]').each(function() {
        var $this = $(this);
        var  expanderConfig = $this.data('expander');

        if (typeof expanderConfig === 'object') {
            var viewportWidth = $(window).width();

            for (var breakpoint in expanderConfig) {
                if (viewportWidth <= breakpoint) {
                    applyExpander($this, expanderConfig[breakpoint]);
                    return;
                }
            }

            $this.expander('destroy');
        } else {
            applyExpander($this, expanderConfig);
        }
    });
}

function applyExpander(element, slicePoint) {
    element.expander({
        slicePoint: slicePoint,
        expandText: 'More',
        userCollapseText: 'Less',
        expandSpeed: 250,
        userCollapse: true,
        expandEffect: 'fadeIn',
        collapseEffect: 'fadeOut'
    });
}

$(document).ready(function() {
    initializeExpander();
});

$(window).resize(debounce(function() {
    initializeExpander();
}, 250)); // 250ms debounce
