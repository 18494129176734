$(document).ready(function() {
    $(document).on('click', '.like-product', function(event) {
        event.preventDefault();

        if ($(this).hasClass('fa-regular')) {
            $(this).removeClass('fa-regular').addClass('fa-solid');
            showNotification('Product liked!', 'success', 'fa-check-circle');
        } else if ($(this).hasClass('fa-solid')) {
            $(this).removeClass('fa-solid').addClass('fa-regular');
            showNotification('Product unliked!', 'info', 'fa-info-circle');
        }
    });
});
