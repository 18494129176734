$(document).ready(function() {
    $(".password-input a").on('click', function(event) {
        event.preventDefault();
        const inputField = $(this).closest('.password-input').find('input');
        const icon = $(this).find('i');

        if(inputField.attr("type") === "text") {
            inputField.attr('type', 'password');
            icon.addClass("fa-eye-slash").removeClass("fa-eye");
        } else if(inputField.attr("type") === "password") {
            inputField.attr('type', 'text');
            icon.removeClass("fa-eye-slash").addClass("fa-eye");
        }
    });
});