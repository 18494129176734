const cartSidebar = {
    init: function () {
        const offcanvasCartElement = $('#offcanvas');

        if (!offcanvasCartElement.length) {
            return;
        }

        const mediaQuery = window.matchMedia('(max-width: 1199px)');

        function handleOffcanvasPositionChange(e) {
            if (e.matches) {
                offcanvasCartElement.removeClass('offcanvas-end').addClass('offcanvas-start');
            } else {
                offcanvasCartElement.removeClass('offcanvas-start').addClass('offcanvas-end');
            }
        }
        handleOffcanvasPositionChange(mediaQuery);

        mediaQuery.addEventListener('change', handleOffcanvasPositionChange);
    }
}

$(document).ready(function() {
    cartSidebar.init();
});
