import Swiper from 'swiper';
import { Autoplay, Mousewheel, Navigation, Pagination, Scrollbar } from 'swiper/modules';

$(document).ready(function () {
    $('.products-slider-section .swiper').each(function () {
        new Swiper($(this)[0], {
            modules: [Autoplay, Mousewheel, Navigation, Pagination, Scrollbar],
            speed: 400,
            spaceBetween: 15,
            slidesPerView: 5, // Default view
            autoplay: {
                delay: 5000,
            },
            navigation: {
                nextEl: $(this).find('.swiper-button-next')[0],
                prevEl: $(this).find('.swiper-button-prev')[0],
            },
            breakpoints: {
                1680: { slidesPerView: 5, spaceBetween: 20 },
                1400: { slidesPerView: 4.6, spaceBetween: 30 },
                1200: { slidesPerView: 3.9, spaceBetween: 40 },
                992: { slidesPerView: 3, spaceBetween: 40 },
                768: { slidesPerView: 2.5, spaceBetween: 40 },
            },
        });
    });
});